import { ParsedUrlQuery } from 'querystring'
import { ValueHash } from './types'

/**
 * Takes in an existing query param object and replaces the name value with the passed-in value.
 * @param name The name of the param to replace.
 * @param value The new value for the param.
 * @param query An existing query param object.
 * @returns A string that can be used as query paramters (e.g. - p1=1&p2=2&p3=3...)
 */
export function replaceQueryParam(name: string, value: string | number, query: ParsedUrlQuery): string {
  return Object.keys(query).reduce((acc: string, key: string, index: number) => {
    return `${acc}${index === 0 ? '' : '&'}${key}=${key === name ? value : query[key]}`
  }, '')
}

/**
 * Returns a string that represents the passed-in query parameter object.
 * @param query An existing query param object.
 * @returns A string that can be used as query paramters (e.g. - p1=1&p2=2&p3=3...)
 */
export function getQueryParams(query: ParsedUrlQuery): string {
  return Object.keys(query).reduce((acc: string, key: string, index: number) => {
    return `${acc}${index === 0 ? '' : '&'}${key}=${query[key]}`
  }, '')
}

/**
 * Returns a single param from the passed-in query object. Note that it's sanitized and any spaces are removed.
 * @param query An existing query param object.
 * @param param A string representation of that param.
 */
export function getQueryParam(query: ParsedUrlQuery, param: string) {
  return (query[param] as string)?.replace(/ /g, '+')
}

/**
 * Returns a Link href based on the passed-in base URL and a set of query params. Only params that actually have
 * values are included in the href (e.g. - so that we don't end up with something like &email=undefined).
 * @param base The base of the href (e.g. - register-or-sign-in)
 * @param params Query params to add to the href.
 */
export function getLinkRef(base: string, params: ValueHash<string | undefined>) {
  let ref = `/${base}?`

  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      return
    }

    if (ref.charAt(ref.length - 1) === '?') {
      ref += `${key}=${params[key]}`
    } else {
      ref += `&${key}=${params[key]}`
    }
  })

  // Remove trailing "?"" in case all params were falsy.
  if (ref.charAt(ref.length - 1) === '?') {
    ref = ref.slice(0, ref.length - 1)
  }

  return ref
}

export function removeQueryStringFromPath(path: string) {
  return path.split('?')[0]
}

/**
 * Removes the passed-in query param (if present) from the passed-in path and returns the path.
 * @param path Example: http://localhost:4201/listing-opportunity/anRL/activity?page=2&sort=created_at
 * @param param Example: page
 * @returns Example: http://localhost:4201/listing-opportunity/anRL/activity?sort=created_at
 */
export function removeQueryParamFromPath(path: string, param: string) {
  const [pathOnly, query] = path.split('?')
  if (!query) {
    return path
  }

  const filteredQueryParts = query.split('&').filter((part) => part.split('=')[0] !== param)

  return filteredQueryParts.length ? `${pathOnly}?${filteredQueryParts.join('&')}` : `${pathOnly}`
}

/**
 * Utility to cleanup and make sure we always have a valid href.
 * @param href The URL to sanitize.
 * @returns The sanitized URL.
 */
export function sanitizeUrl(url: string) {
  // NOTE: Add checks/cleanup here as required.

  // Remove any // from the URL.
  return url.replace(/\/\//g, '/')
}
